.strong-border-right {
  border-right-width: 2px !important;
}
.strong-border-top {
  border-top-width: 2px !important;
}
.strong-border-left {
  border-left-width: 2px !important;
}
.strong-border-bottom {
  border-bottom-width: 2px !important;
}

.tg {
  border-collapse: collapse;
  border-spacing: 0;
}

.tg td {
  font-family: Arial, sans-serif;
  font-size: 14px;
  padding: 5px;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  word-break: normal;
  border-color: black;
}

.tg th {
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  padding: 10px 5px;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  word-break: normal;
  border-color: black;
}

.tg .tg-0lax {
  text-align: center;
  vertical-align: middle;
}

@media only screen and (min-width: 601px) {
  .tg .tg-0lax {
    width: 45px;
    height: 45px;
  }
}

@media only screen and (max-width: 600px) {
  .tg .tg-0lax {
    width: 11%;
    height: 10vw;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
}

.highlight-found {
  animation: pulse 5s;
}

.highlight {
  animation: pulse2 5s;
}

@keyframes pulse {
  0% {
    background-color: #ffffff;
  }
  25% {
    background-color: #ffff00;
  }
  50% {
    background-color: #ffc40f;
  }
  75% {
    background-color: #ffff00;
  }
  100% {
    background-color: #ffffff;
  }
}

@keyframes pulse2 {
  0% {
    background-color: #ffffff;
  }
  25% {
    background-color: #34b1e2;
  }
  50% {
    background-color: #7ff393;
  }
  75% {
    background-color: #34b1e2;
  }
  100% {
    background-color: #ffffff;
  }
}
